import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/blog/blogs-details.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`If your dog has a seizure, one of the first questions you should ask “How quickly can I get in touch with my veterinarian?” To reiterate that, if your has just had a seizure (and you have not talked with a veterinarian about your dog’s seizures before) the first thing you need to do is talk to your veterinarian. Once you’ve done that, come back to this article. It’s not going anywhere — we promise.`}</p>
    <p>{`Once you’ve talked with your veterinarian, its possible you’ll feel overwhelmed and maybe even a sense of despair. Don’t worry, we’ve compiled a guide to the essential information that you need to know about canine seizures. We’ve also got tips on how to help YOU handle dealing with your furry friend’s seizures.`}</p>
    <p>{`A word of advice: We are not licensed veterinarians (or unlicensed veterinarians either — no veterinarian at all!). This article is not medical advice. Every animal is unique and may have very different needs. You should consult with your veterinarian to find out exactly how to best handle your pet’s seizures.`}</p>
    <h2>{`What Even Is a Seizure?`}</h2>
    <p>{`If you’ve never seen a seizure before, you might be wondering “What even is a seizure?” According to John Hopkins, A seizure is a burst of uncontrolled electrical activity between brain cells (also called neurons or nerve cells) that causes temporary abnormalities in muscle tone or movements (stiffness, twitching or limpness), behaviors, sensations or states of awareness (source). While this definition can be confusing if you’re not a med-school student, it does a great job covering the full range of severity that a seizure can entail.`}</p>
    <p>{`Canine seizures include a wide range of behavior that can include increased saliva generation, pacing, flinching, barking at non-existent objects, biting the air (this can often look like trying to bite a fly that doesn’t exist), and even the full on grand mal seizure (the type of seizures that are often portrayed in movies).`}</p>
    <p>{`There are two major classes or groups of seizures: focal onset and generalized onset.`}</p>
    <h2>{`Focal Onset Seizures`}</h2>
    <p>{`Focal onset seizures (Often referred to simply as “Focal Seizures”) start in one area and can spread across the brain and cause mild or severe symptoms, depending on how the electrical discharges spread (source). Generally speaking, the pacing, flinching, barking at non-existent objects, and biting the air behaviors are representative of a focal seizure.`}</p>
    <h2>{`Generalized Seizures`}</h2>
    <p>{`Generalized seizures often involve changes in muscle activity, which can include limb stiffening, jerking, and loss of muscle control or convulsions. Generalized seizures with a non-motor onset are often called absence seizures and involve the person ‘zoning out’ for a brief period (source).`}</p>
    <p>{`Generalized seizures have several sub-categories for describing the type of seizure. These are myoclonic seizures, tonic-clonic seizures, tonic seizures, atonic seizures, absence seizures, and myoclonic absence seizures. We’ve listed brief definitions below, but your veterinarian should be able to go more in detail or recommend you to a specialist who can help educate you, if they can not.`}</p>
    <h2>{`Myoclonic Seizures`}</h2>
    <p>{`Myoclonic seizures are brief seizures that involve quick jerks of a muscle or muscle group.`}</p>
    <h2>{`Tonic-Clonic Seizures`}</h2>
    <p>{`Tonic-Clonic seizures are seizures that cause the pet’s limbs to stiffen and become very rigid, while also causing repetitive rhythmic jerking. Dogs may also produce excess saliva or lose control of their bladder during a Tonic-Clonic seizure.`}</p>
    <h2>{`Tonic seizures`}</h2>
    <p>{`Tonic seizures cause the pet’s limbs to stiffen and become very rigid. Often times, this can lead to the pet falling and sometimes injuring themselves.`}</p>
    <h2>{`Atonic Seizures`}</h2>
    <p>{`Atonic Seizures cause a sudden loss or decrease of normal muscle tone, with the pet often falling to the ground if standing prior to the seizure. The pet may have a difficult time standing up due to their limbs appearing to not be able to support their weight.`}</p>
    <h2>{`Absence Seizures`}</h2>
    <p>{`Absence seizures do not involve major muscle groups but instead can cause a lapse of awareness and activity in the canine.`}</p>
    <h2>{`Myoclonic Absence Seizures`}</h2>
    <p>{`Myoclonic Absence seizures manifest themselves in dogs via twitching muscle groups (sometimes including the muscles around the pet’s nose), but additionally include behavioral and awareness changes like that of an Absence Seizure.`}</p>
    <h2>{`What Causes a Seizure?`}</h2>
    <p>{`Unfortunately, there are many causes of seizures in our canine friends. Thankfully, we have options to help manage most of the causes. The most common cause of seizures in dogs is idiopathic epilepsy, which is an inherited disorder. At this point, the exact cause of idiopathic epilepsy is unknown. Other causes include liver disease, kidney failure, brain tumors, brain trauma, or toxins (source).`}</p>
    <p>{`Generally, a veterinarian will try to help you rule out the possible causes of your pet’s seizure. They may ask if the dog has eaten anything that they shouldn’t have recently (a possible sign of toxin related seizures), may run bloodwork to help diagnose the possibilities of liver disease or kidney failure, and even an MRI to search for brain tumors or brain trauma. When none of these possibilities are found, generally the pet is then diagnosed with idiopathic epilepsy.`}</p>
    <h2>{`How are Seizures treated?`}</h2>
    <p>{`Once the cause of the seizures are found, treatment can begin. If the seizures are toxin related, treatment can be as simple as removing the toxin from the pet’s environment. With liver disease or kidney failure, the treatment can vary tremendously, but your veterinarian can help put together a plan to help manage that. Brain tumors can often be combated by removing the tumors, but this is a decision that needs to be thought out as the benefits may not always outweigh the costs (and not just financially). With idiopathic epilepsy, the dog is often prescribed different medications until the veterinarian finds the right combination of medication, dosage, and frequency to help manage your dog’s unique seizures.`}</p>
    <p>{`If your dog is prescribed medication, it is extremely important to follow the veterinarian’s recommendation precisely. Most medications need to be given multiple times per day and effect the chemical composition in the canine’s body. Missing a medication can cause a chemical imbalance and, in the worst case, even cause a seizure.`}</p>
    <h2>{`Will My Dog Be Okay?`}</h2>
    <p>{`Depending on the dogs source of seizures, age, health and condition, most dogs can continue enjoying their lives. Luna, the dog that Luna Journal was built for, has idiopathic epilepsy and despite still having seizures from time to time, manages to live an enjoyable, healthy life with her family. Your veterinarian should be able to give you a general idea of your dog’s quality of life given their condition.`}</p>
    <h2>{`How do I manage my dog’s seizures?`}</h2>
    <p>{`Coping with your dog’s medical condition can be difficult. Some generalized onset seizures can look like they’re causing your dog pain. It’s worth mentioning that, despite looking that as if they might, a dog’s seizures generally do not cause them pain (source). Some recommendations for handling the stress of your dog’s seizures include:`}</p>
    <ul>
      <li parentName="ul">{`Therapy`}</li>
      <li parentName="ul">{`Journaling your pet’s behavior and activities (Some vets recommend this for diagnosis purposes, too)`}</li>
      <li parentName="ul">{`A support group (`}<a parentName="li" {...{
          "href": "https://www.facebook.com/groups/CanineSeizureSupportGroup"
        }}>{`there’s a great facebook group here`}</a>{`!)`}</li>
      <li parentName="ul">{`Researching more information about seizures and setting clear expectations with your family members.`}</li>
      <li parentName="ul">{`Continue enjoying life with your canine companion.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      